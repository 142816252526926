import { createSlice, } from '@reduxjs/toolkit';
// import { baseUrl } from 'src/config';
// import axios from 'axios';
// import { useSelector } from 'react-redux';

const initialState = {
    roles: [],
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setRoles(state, action) {
            const { roles } = action.payload;
            state.roles = roles;
        },
    }
});



// Actions =========== START
// export const fetchPermission = ({ email, password }) => async (dispatch) => {
//     const url = baseUrl('/login');

//     dispatch(slice.actions.setRoles({ roles: resp.data }))

// }
// Actions =========== END




// use Auth custom hook
export const useDashboard = () => ({
    // fetchPermission,
    // roles: useSelector((state) => state.roles)
})


export default slice;
