import ExtendedSidebarLayout from "src/layouts/ExtendedSidebarLayout";

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

const Member = Loader(lazy(() => import('src/pages/Member')));
const UsersList = Loader(lazy(() => import('src/pages/Users/index')));
const EditUser = Loader(lazy(() => import('src/pages/Users/EditUser')));
const Dashboard = Loader(lazy(() => import('src/pages/Dashboard')));
const AddRoles = Loader(lazy(() => import('src/pages/Roles/AddRoles/index')));
const AddBooking = Loader(lazy(() => import('src/pages/Booking/addBooking')));
const NewsFeed = Loader(lazy(() => import('src/pages/NewFeed')));
const RolesList = Loader(lazy(() => import('src/pages/Roles')));
const AddResources = Loader(lazy(() => import('src/pages/Resources/AddResource')));
const EditResource = Loader(lazy(() => import('src/pages/Resources/EditResource')))
const Resources = Loader(lazy(() => import('src/pages/Resources')))
const Events = Loader(lazy(() => import('src/pages/Events')));
const AddEvent = Loader(lazy(() => import('src/pages/Events/AddEvent')))
const EditEvent = Loader(lazy(() => import('src/pages/Events/EditEvent')))
const Calender = Loader(lazy(() => import('src/pages/Events/Calender')))
const Staff = Loader(lazy(() => import('src/pages/Staff')));
const Visitor = Loader(lazy(() => import('src/pages/Visitor')));
const DiscountAndPromotion = Loader(lazy(() => import('src/pages/Discount-And-Promotion')));
const Approval = Loader(lazy(() => import('src/pages/Approval')));
const Login = Loader(lazy(() => import('src/pages/Auth/Login/Basic/index')));
const ForgetPassword = Loader(lazy(() => import('src/pages/Auth/RecoverPassword/index')));
const ResetPassword = Loader(lazy(() => import('src/pages/Auth/SetNewPassword/index')));
const Booking = Loader(lazy(() => import('src/pages/Booking/DashboardBooking')));
const EditRoles = Loader(lazy(() => import("src/pages/Roles/EditRoles")))
const NoPermission = Loader(lazy(() => import("src/pages/Status/NoPermission")))
const ViewAllBooking = Loader(lazy(() => import("src/pages/Booking/ViewAllBooking")))
const ViewPendingBooking = Loader(lazy(() => import("src/pages/Booking/PendingBooking")))
const Categories = Loader(lazy(() => import("src/pages/Categories/Categories")))
const BookingDetail = Loader(lazy(() => import("src/pages/Booking/BookingDetail")))
const Checkout = Loader(lazy(() => import("src/pages/Checkout/Checkout")))
const AddMember = Loader(lazy(() => import("src/pages/Member/AddMember")))
const EditMember = Loader(lazy(() => import("src/pages/Member/EditMember")))
const Company = Loader(lazy(() => import("src/pages/Company")))
const AddCompany = Loader(lazy(() => import("src/pages/Company/AddCompany")))
const EditCompany = Loader(lazy(() => import("src/pages/Company/EditCompany")))
const ViewMember = Loader(lazy(() => import("src/pages/Member/ViewMember")))
const ViewCompany = Loader(lazy(() => import("src/pages/Company/ViewCompany")))
const ViewNewsFeed = Loader(lazy(() => import("src/pages/NewFeed/ViewNewsFeed")))
const AddStaff = Loader(lazy(() => import("src/pages/Staff/AddStaff")))
const EditStaff = Loader(lazy(() => import("src/pages/Staff/EditStaff")))
const ViewStaff = Loader(lazy(() => import("src/pages/Staff/ViewStaff")))
const AddNewsFeed = Loader(lazy(() => import("src/pages/NewFeed/AddNewsfeed")))
const EditNewsFeed = Loader(lazy(() => import("src/pages/NewFeed/EditNewsFeed")))
// const UserLogin = Loader(lazy(() => import("src/pages/Auth/UserLogin/UserLogin")))
 
const childRoutes = [
     {
         path: '/',
         element: <Navigate to="dashboard" replace />
    },
    {
        path: 'dashboard',
        element: <Dashboard />
    },
    // roles
    {
        path: 'roles/list',
        element: <RolesList />
    },
    {
        path: 'roles/add',
        element: <AddRoles />
    },
    {
        path: 'roles/edit/:id',
        element: <EditRoles />
    },
    {
        path: 'roles/show/:id',
        element: <EditRoles />
    },
    {
        path: 'member',
        element: <Member />
    },
    // resources
    {
        path: 'resources/list',
        element: <Resources/>
    },
    {
        path: '/user/list',
        element: <UsersList />
    },
    {
        path: 'resources/add',
        element: <AddResources />
    },
    {
        path: 'resources/edit/:id',
        element: <EditResource />
    },
    {
        path: 'user/edit/:id',
        element: <EditUser />
    },
    {
        path: "categories/list",
        element: <Categories/>
    },
    {
        path: 'events/list',
        element: <Events />
    },
    {
        path: 'events/calendar',
        element: <Calender />
    },
    {
        path: 'events/add',
        element: <AddEvent/>
    },
    {
        path: 'event/edit/:id',
        element: <EditEvent/>
    },

    {
        path: 'Approval/approval-payment',
        element: <Approval />
    },
    {
        path: 'news-feed',
        element: <NewsFeed />
    },
    {
        path: 'booking',
        element: <Booking />
    },
    {
        path: 'staff',
        element: <Staff />
    },
    {
        path: 'visitor',
        element: <Visitor />
    },
    {
        path: '/add/booking',
        element: <AddBooking />
    },
    {
        path: '/booking/all',
        element: <ViewAllBooking />
    },
    {
        path: '/booking/pending',
        element: <ViewPendingBooking />
    },
    {
        path: "/booking/:id",
        element: <BookingDetail/>
    },
    {
        path: 'discount-and-promotion',
        element: <DiscountAndPromotion />
    },
    {
        path: '/access/forbidden',
        element: <NoPermission />
    },
    {
        path: "/new-booking",
        element: <Checkout/>
    },
    {
        path: "/member/add",
        element: <AddMember/>
    },
    {
        path: "/member/:id",
        element: <EditMember/>
    },
    {
        path: 'company',
        element: <Company/>
    },
    {
        path: "company/add",
        element: <AddCompany/>
    },
    {
        path: "company/:id",
        element: <EditCompany/>
    },
    {
        path: "member/view/:id",
        element: <ViewMember/>
    },
    {
        path: "company/view/:id",
        element: <ViewCompany/>
    },
    {
        path: "news-feed/:id",
        element: <ViewNewsFeed/>
    },
    {
        path: "Staff/add",
        element: <AddStaff/>
    },
    {
        path: "Staff/:id",
        element: <EditStaff/>
    },
    {
        path: "Staff/:id/view",
        element: <ViewStaff/>
    },
    {
        path: "news-feed/add",
        element: <AddNewsFeed/>
    },
    {
        path: "news-feed/:id/edit",
        element: <EditNewsFeed/>
    }

];
const router = [
    {
        path: '/login',
        element: <Login />
    },
    // {
    //     path: "/login",
    //     element: <UserLogin/>
    // },
    {
        path: '/ForgetPassword',
         element: <ForgetPassword />
    },
    {
        path: '/ResetPassword/:token',
        element: <ResetPassword />

    },
    {
        path: '/',
        element: <ExtendedSidebarLayout />,
        children: childRoutes
    }
];
export default router;
