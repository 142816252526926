import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';



const menuItems = [
  {
    heading: 'Overview',
    permission: ["user.search.domain"],
    items: [
      {
        name: 'Dashboard',
        icon: AssignmentIndTwoToneIcon,
        link: 'dashboard',
        permission: "user.search.domain"
      }
    ]
  },
  {
    heading: 'Management',
    permission: ["admin.all.users" , "admin.roles.index" , "admin.all.resource" ,"admin.all.category", "admin.list.booking"],
    items: [
      {
        name: 'User',
        icon: AssignmentIndTwoToneIcon,
        link: '/user/list',
        permission: "admin.all.users"
      },
      {
        name: 'Roles & Permissions',
        icon: AssignmentIndTwoToneIcon,
        link: 'roles/list',
        permission: "admin.roles.index"
      },
      {
        name: 'Resources',
        icon: AssignmentIndTwoToneIcon,
        permission: "admin.all.resource",
        items: [
          {
            name: 'List',
            icon: AssignmentIndTwoToneIcon,
            link: 'resources/list',
            permission: "admin.all.resource"
          },
          {
            name: 'Categories',
            icon: AssignmentIndTwoToneIcon,
            link: 'categories/list',
            permission: "admin.all.category"
          }
        ]
      },
      {
        name: 'Booking',
        icon: AssignmentIndTwoToneIcon,
        permission: "admin.list.booking",
        items: [
          {
            name: 'Overview',
            icon: AssignmentIndTwoToneIcon,
            link: 'booking',
            permission: "admin.list.booking"
          },
          {
            name: 'List',
            icon: AssignmentIndTwoToneIcon,
            link: 'booking/all',
            permission: "admin.list.booking"
          }
        ]
      },
      {
        name: 'Discount & Promotion',
        icon: AssignmentIndTwoToneIcon,
        link: 'discount-and-promotion',
        permission: "user.login"
      },
    ]
  },
  {
    heading: "Members",
    permission: ["user.login"],
    items: [
       
      {
        name: 'Member',
        icon: AssignmentIndTwoToneIcon,
        link: 'member',
        permission: "user.login"
      },
      {
        name: "Company",
        icon: AssignmentIndTwoToneIcon,
        link: 'company',
        permission: "user.login"
      }
    ]
  },
  {
    heading: 'Analyze',
    permission: ["user.login"],
    items: [
        {
        name: 'Events',
        icon: AssignmentIndTwoToneIcon,
        link: '/extended-sidebar/dashboards/Events',
        permission: "user.login",
        items: [
          {
            name: 'List Events',
            link: 'events/list',
            permission: "user.login",

          },
          {
            name: 'Calender',
            link: 'events/calendar',
            permission: "user.login",

          }
        ]
      },
      {
        name: 'News Feed',
        icon: AssignmentIndTwoToneIcon,
        link: 'news-feed',
        permission: "user.login"
      },
      {
        name: 'Approval',
        icon: AssignmentIndTwoToneIcon,
        link: 'Approval/approval-payment',
        permission: "user.login"
      },
      {
        name: 'Staff',
        icon: AssignmentIndTwoToneIcon,
        link: 'Staff',
        permission: "user.login"
      },
      {
        name: 'Visitor',
        icon: AssignmentIndTwoToneIcon,
        link: 'visitor',
        permission: "user.login"
      },

    ]
  },
];

export default menuItems;