import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector
} from 'react-redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import calender from "src/slices/calendar"
import authSlice from './authSlice';
import dashboardSlice from './dashboardSlice';
/*
    {
        auth: { token:'', user:{....} },  // <= authSlice.reducers
        dashboard: { // <= dashboard.reducers
            permissions:[...],
            roles:[],
            permissionForm:{...},
            feeds:[],
            staff:[]
        }
    }
*/

const store = configureStore({
    reducer: combineReducers({
        auth: authSlice.reducer,
        dashboard: dashboardSlice.reducer,
        calender: calender.reducer
    }),
    devTools: true
});



export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();

export default store;