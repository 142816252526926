import { ListSubheader, alpha, Box, List, styled } from '@mui/material';
import { useCallback,  } from 'react';
import {useSelector} from "react-redux"
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SidebarMenuItem from './item';
import menuItems from './items';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.Mui-active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);


// function useLocalStorage(key, initialState) {
//   const [value, setValue] = useState(localStorage.getItem(key) ?? initialState);
//   const updatedSetValue = useCallback(
//     newValue => {
//       if (newValue === initialState || typeof newValue === 'undefined') {
//         localStorage.removeItem(key);
//       } else {
//         localStorage.setItem(key, newValue);
//       }
//       setValue(newValue ?? initialState);
//     },
//     [initialState, key]
//   );
//   return [value, updatedSetValue];
// }



function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();

  const user = useSelector(state => state.auth.user.Roles)
  const p = user && user[0]?.permissions
  const permissions = p?.map(item => item.name)
  
  const renderSidebarMenuItems = ({ items, path }) => {

    return (
    <SubMenuWrapper>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
      </List>
    </SubMenuWrapper>
    )
  }
  // ), [permissions]);
  
  const reduceChildRoutes = useCallback(({ ev, path, item }) => {
    const key = item.name;

    const type = typeof item.permission

    // console.log("item => ", typeof item.permission)
  
    const allowed = type === "string" ? permissions?.includes(item.permission) : item.permission?.map(s => permissions?.includes(s))
  
    const exactMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: true
          },
          path
        )
      : false;
  
      
      if(allowed){
  
        if (item.items) {
          const partialMatch = item.link
        ? !!matchPath(
            {
              path: item.link,
              end: false
            },
            path
          )
        : false;
  
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
          >
          {renderSidebarMenuItems({
            path,
            items: item.items
          })}
        </SidebarMenuItem>
      );
    } else {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
          icon={item.icon}
          />
          );
        }
      }
        
        return ev;
  }, [permissions]);

  // let ar = []

  // menuItems.map(s => {
  //  s.permission.map(ss => {
  //    return ar.push(ss)
  //  })
  //  return s
  // })

  // const a = ar.map(arr => {
  //   return permissions?.includes(arr)
  // })

  // console.log(a)

  console.log(permissions)
  

  return (
    <>
      {menuItems.map((section, ) => {
        // const a = section.permission.filter(s => {
        //   return permissions?.includes(s)
        // })
        // a.map((_, ai) => console.log("a",a[ai]))
        const allowed = section.permission.some(s => permissions?.includes(s))

        console.log(section)

        return (
        <MenuWrapper key={section.heading}>
            {allowed && (
              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    {t(section.heading)}
                  </ListSubheader>
                }
              >
                {renderSidebarMenuItems({
                  items: section.items,
                  path: location.pathname
                })}
              </List>  
            )}
          {/* {permissions?.includes(section.permission) && (
          )} */}
        </MenuWrapper> 
        )
      }
      )}
    </>
  );
}

export default SidebarMenu;
