import { useEffect } from 'react';
import NProgress from 'nprogress';
import Logo from "src/assets/sfd_trans_light.png";
import { Box} from '@mui/material';



function SuspenseLoader() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >

      <img width="150rem" alt="logo" src={Logo}/>
    </Box>
  );
}

export default SuspenseLoader;
