import { useRoutes, useNavigate} from 'react-router-dom';
import router from 'src/router';
import Cookies from "js-cookie"
import { useDispatch, useSelector} from 'react-redux';
// import axios from "axios"
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { setUser } from './redux/authSlice';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
// import { setUser} from './redux/authSlice';


function App() {
    const content = useRoutes(router);
    const auth = { isInitialized: true }
    // const { setUserr } = useAuth();
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    // const userToken = Cookies.get("userToken")
    const adminToken = Cookies.get("token")
    // on refresh check token
    // useEffect(() => {
    //     const token = localStorage.getItem('token');
    //     if (token) {
    //         dispatch(setSession(token));
    //     }
    // }, []);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const changed = useSelector(state => state.auth.changed)

    console.log(changed)

    useEffect(() => {
        // console.log(userToken)
        // console.log(user)
        const getUserInfo = async () => {
            const res = await fetch("https://api.cowork.speedforce.digital/api/v1/user", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${adminToken}`
                }
            })
            const user = await res.json()
            if(res.status === 200){
                dispatch(setUser(user))
                localStorage.setItem("permissions", JSON.stringify(user?.Roles[0].permissions))
            }else{
                alert("Your session has expired, kindly log in again")
                Cookies.remove("token")
                navigate("/login")
            }
        }

        getUserInfo()

        
        if(!adminToken){
            navigate("/login")
        }
        if(adminToken && window.location.pathname === "/login"){
            navigate("/dashboard")
        }
        // console.log(window.location.pathname)
        // getUserInfo()
    }, []);

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <CssBaseline />
                    {auth.isInitialized ? content : <AppInit />}
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
