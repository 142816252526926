import { createSlice } from '@reduxjs/toolkit';
// import { baseUrl } from 'src/config';
import axios from 'axios';
import Cookies from 'js-cookie'
import { useSelector } from 'react-redux';


const initialState = {
    user: { }
};
// createSlice, dispatch, 
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action) {
            const user  = action.payload;
            state.user = user; // setting
        },
    }
});

// Api calls Function / Side Effects =========== START
export const setSession = (name, token) => async () => {
   Cookies.set(name, token)
}
// export const setUserr = (user) => async (dispatch) => {
//     console.log("here")
//     dispatch(slice.actions.setUser(user))
//     console.log("here")
// }

export const login = (email, password, name, successCb) => async (dispatch) => {
    
    try {
        const response = await axios.post("https://api.cowork.speedforce.digital/api/v1/login", {
            email,
            password
        });
        // const { token } = response?.data
        dispatch(setSession(name, response?.data?.token));
        // const res = await axios.post(`http://api.cowork.speedforce.digital/api/v1/user`, {
        //             headers: {
        //                 Authorization: `Bearer ${response?.data?.token}`    
        //             }
                    
        // })
        
        // console.log(res)
        successCb()
    } catch (error) {
        console.log(error)
    }
}


export const adminLogin = (email, password, name, successCb) => async (dispatch) => {
    try{
        const response = await axios.post("https://api.cowork.speedforce.digital/api/v1/admin/login", {
            email, password
        })
        if(response.status === 200){
            dispatch(setSession(name, response.data.token))
            successCb()
            const res = await fetch("https://api.cowork.speedforce.digital/api/v1/user", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${response?.data?.token}`
                }
            })
            const user = await res.json()
            dispatch(setUser(user))
            localStorage.setItem("permissions", JSON.stringify(user?.Roles.permissions))

        }
    }catch(err){
        console.log(err)
    }
}


export const logout = (successCb) => async () => {
    const token = Cookies.get("token")
    // call logout api here
    console.log(token)
    const res = await fetch('https://api.cowork.speedforce.digital/api/v1/logout', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const user = await res.json()
    console.log(user) 
    
    if(user.message === "user logged out"){
        Cookies.remove("token")
        successCb()
    }

    
}


// Api calls Function / Side effects =========== END



// use Auth custom hook
export const useAuth = () => ({
    login,
    logout,
    user: useSelector((state) => state.auth.user),
    type: useSelector((state) => state.auth.type),
    adminLogin,
})

export const {setUser} = slice.actions


export default slice;
